<template>
    <div class="pagination">
        <div class="left_line"></div>
        <div class="center_box">
            <button type="button" class="previous_page" @click="previous_page" :disabled="+currentPage === 1">Previous</button>

            <div class="select_page">
                <div class="pagination_status" id="pagination-status" @click="toggleListPagination">Page {{currentPage}} of {{Math.ceil(count / 30)}}</div>
                <v-list class="pagination_list" v-if="viewListPagination" id="pagination-list" :class="{'show': viewListPagination}" v-click-outside="clickOutsidePagination">
                    <v-list-item v-for="(item, i) of Math.ceil(count / 30)" :key="i" @click="selectPage(item)" :id="`list-item-${item}`">
                        <v-list-item-title :class="{'active': item === currentPage}">Page {{ item }} of {{Math.ceil(count / 30)}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>

            <button type="button" class="next_page" @click="next_page" :disabled="+currentPage >= Math.ceil(count / 30)">Next</button>
        </div>
        <div class="right_line"></div>
    </div>
</template>

<script>
export default {
    name: 'musicReleasePagination',
    props: ['count', 'currentPage', 'commit'],
    data() {
        return {
            viewListPagination: false
        };
    },
    methods: {
        previous_page() {
            if (Number(this.currentPage) < 1) {
                this.$store.commit(this.commit, 1);
            }
            this.$store.commit(this.commit, Number(this.currentPage) - 1);
        },
        next_page() {
            if (Number(this.currentPage) >= Math.ceil(this.count / 30)) {
                this.$store.commit(this.commit, this.count / 30);
            }
            this.$store.commit(this.commit, Number(this.currentPage) + 1);
        },
        selectPage(item) {
            this.$store.commit(this.commit, item);
            this.viewListPagination = false;
        },
        toggleListPagination() {
            this.viewListPagination = !this.viewListPagination;
            let paginationList = document.getElementById(`list-item-${this.currentPage}`);
            if (paginationList) {
                paginationList.scrollIntoView();
            }
        },
        clickOutsidePagination: function (event) {
            if (event.target.parentElement !== null) {
                if (event.target.id !== 'pagination-status' && event.target.parentElement.id !== 'pagination-list') {
                    this.viewListPagination = false;
                }
            }
        }
    },
};
</script>
