<template>
    <div class="events-layout">
        <projectIsUnderConstructionWarning style="position: relative; top: 60px; z-index: 1;"></projectIsUnderConstructionWarning>
        <slot></slot>
    </div>
</template>

<script>
import projectIsUnderConstructionWarning from "@/components/projectIsUnderConstructionWarning.vue";
export default {
    name: "eventsLayout",
    components: {
        projectIsUnderConstructionWarning
    }
}
</script>